import { useQuery } from 'react-query'
import { SaleGroup } from '../../types/vault/SaleGroup'
import ServerSideVaultApi from '../../lib/api/ServerSideVaultApi'
import VaultApi from '../../lib/api/VaultApi'

export const fetchSaleGroup = (id: string): Promise<SaleGroup> =>
  VaultApi.doRequest({
    method: 'GET',
    url: `/v2/market/sale-groups/${id}`,
  }).then((res) => res.json())

export const fetchSaleGroupServerSide = (id: string): Promise<SaleGroup> =>
  ServerSideVaultApi.doRequest({
    method: 'GET',
    url: `/v2/market/sale-groups/${id}`,
  }).then((res) => res.json())

export const useSaleGroup = (id: string, options?: any) =>
  useQuery(['saleGroup', { id }], () => fetchSaleGroup(id), options)
