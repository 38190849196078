import { Box, Skeleton } from '@chakra-ui/react'
import { useHeroBanners } from 'hooks/useHeroBanners'
import { Carousel } from 'components/organisms'
import { GenericHeroBanner } from './GenericHeroBanner'

export const HeroCarousel = () => {
  const { data: heroBanners = [], isLoading } = useHeroBanners()

  return (
    <Box
      sx={{
        '.swiper-pagination': {
          bottom: { base: '24px', md: '18px' },
        },
        '.swiper-pagination-bullet': {
          width: '10px',
          height: '10px',
          margin: '0 5px',
        },
      }}
    >
      {isLoading && (
        <Skeleton
          h={{ base: '640px', md: '720px' }}
          w="full"
          startColor="gray.800"
          endColor="bright.200"
        />
      )}

      {heroBanners.length > 0 && (
        <Carousel
          items={heroBanners.map((heroBanner) => (
            <GenericHeroBanner key={heroBanner.id} {...heroBanner} />
          ))}
        />
      )}
    </Box>
  )
}
