import VaultApi from 'lib/api/VaultApi'
import { useQuery } from 'react-query'
import { HeroBanner } from 'types/vault/v2/HeroBanner'

export const fetchHeroBanners = (): Promise<HeroBanner[]> =>
  VaultApi.doRequest({
    method: 'GET',
    url: `/v2/market/hero-banners`,
  }).then((res) => res.json())

export const useHeroBanners = () => useQuery('hero-banners', fetchHeroBanners)
