import { useQuery } from 'react-query'
import VaultApi from '../lib/api/VaultApi'

export interface OpenlootStats {
  timeframe: string
  transactionsCount: number
  transactionsUSDVolume: number
  itemsVolume: number
  rentalsVolume: number
  windowStart: Date
  windowEnd: Date
}

function fetchStats(): Promise<OpenlootStats[]> {
  return VaultApi.doRequest({
    method: 'GET',
    url: '/v2/market/openloot-stats',
  }).then((res) => res.json())
}

export function useOpenlootStats() {
  return useQuery('openloot-stats', fetchStats)
}
